import React from "react"
import HitlistPresentation from "../components/HitlistPresentation/HitlistPresentation"
import Section from "../components/Section"
import Heading from "../components/Typography/Heading"
import Text from "../components/Typography/Text"
import Divider from "../components/Divider"
import Footer from "../components/Footer"
import Container from "../components/Layout/Container"
import StoreButtons from "../components/StoreButtons"
import {Helmet} from "react-helmet";
import SEO from "../components/seo"
import HitlistExplanation from "../images/priznaky_hitlist.png"
import HeaderNavigation from "../components/HeaderNavigation"

const ComponentPlayground = () => (
  <Container>
    <SEO title={"Hitlist | Pomocník do vrecka pri histamínovej intolerancii"} />
    <Helmet>
        <script type="text/plain" cookie-consent="tracking" src="https://www.googletagmanager.com/gtag/js?id=UA-135060322-2"></script>
        <script type="text/plain" cookie-consent="tracking">
          window.dataLayer = window.dataLayer || [];
          dataLayer.push('js', new Date());
          dataLayer.push('config', 'UA-135060322-2');
        </script>
      </Helmet>
    <HeaderNavigation />
    <main>
      <Divider spaceOnly />
      <HitlistPresentation />
      <Divider spaceOnly small />
      <Section
        title={
          <Heading centered headingSize={1}>
            Čo dokáže?
          </Heading>
        }
        body={
          <>
            <Heading centered white headingSize={2}>
              Odštartujte nízkohistamínovú diétu so Švajčiarskym zoznamom
            </Heading>
            <Text white>
              Švajčiarsky zoznam je výsledkom štúdie,ktorú realizovala
              organizácia SIGHI. Tento zoznam je výborný zo začiatku diéty pri
              skúšaní potravín. Odporúča sa dôsledne ho dodržiavať v prvých 4 až
              6 týždňoch diéty. Nájdete v ňom hodnotenia potravín na stupnici od
              0-3, ktoré vyplynuli z prieskumu SIGHI.
            </Text>

            <Divider white />

            <Heading centered white headingSize={2}>
              Hodnotenie potravín
            </Heading>
            <Text white>
              V aplikácii je tolerancia potravín podľa švajčiarskeho zoznamu
              označená nasledovne:
            </Text>

            <img
              alt="Zelená farba znamená žiadne príznaky. Žltá farba znamená mierne príznaky. Oranžová farba znamená stredné príznaky. Červená farba znamená silné príznaky."
              src={HitlistExplanation}
              width="250"
              style={{ display: "block", margin: "10px auto 0 auto" }}
            />

            <Divider white />

            <Heading centered white headingSize={2}>
              Pozor, histamínová intolerancia je veľmi individuálna!
            </Heading>
            <Text white>
              To, čo niekomu vôbec neškodí, môže vám prekážať. Všetko závisí od
              individuálnych príčin histamínovej intolerancie. Preto zoznam
              slúži len ako odrazový mostík a svoju vlastnú individuálnu
              citlivosť na rôzne potraviny musíte zisťovať sami. Pomôže vám s
              tým eliminačná diéta.
            </Text>

            <Divider white />

            <Heading centered white headingSize={2}>
              Skúšajte nové potraviny
            </Heading>
            <Text white>
              Eliminácia potravín je metóda na vybudovanie si vlastného zoznamu
              potravín skúšaním a hodnotením potravín. Každý deň si môžete
              ohodnotiť potraviny,ktoré ste zjedli a tak si postupným skúšaním
              vybudujete zoznam tolerovaných a netolerovaných potravín. Cieľom
              má byť spoznanie svojho vlastného tela, aby ste sami vedeli čo
              najlepšie posúdiť, čo vám robí dobre a čo naopak nie.
            </Text>
          </>
        }
        chin={
          <>
            <StoreButtons />
          </>
        }
      />

      <Footer />
    </main>
  </Container>
)

export default ComponentPlayground
