import React, { Component } from "react"
import Text from "../Typography/Text"
import Row from "../Layout/Row"
import { Link } from "gatsby"
import styles from "./headerNavigation.module.css"

export default class HeaderNavigation extends Component {
  render() {
    return (
      <header className={styles.header}>
        <nav style={{ height: "100%", overflow: "auto" }}>
          <Row
            rowMobile
            style={{ height: "100%", overflowX: "auto", flexFlow: "row" }}
          >
            <Text white style={{ alignSelf: "center" }}>
              <Link className={styles.links} to={"/r/vegezemla"}>
                Jedlá na inšpiráciu
              </Link>
            </Text>
            {/**
             * IF more entries are entered, then add flex: 1 0 auto to all items
             */}
            {/* <Text white style={{ alignSelf: 'center', margin: '0px 10px', flex: '1 0 auto' }}>Jedlá na inšpiráciu</Text> */}
          </Row>
        </nav>
      </header>
    )
  }
}
